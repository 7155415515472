export default (key: string, action: string, params: any = {}) => {
  const { frame } = params;
  return {
    path: `/stat/${key.replace(/__(.+?)__/g, frame)}/${action}`.replace(
      /\/\//g,
      '/'
    ),
    params,
  };
};
